import React from "react";
import { GetStaticProps } from "next";
import { getLanguageStaticProps } from "../src/helper/languageHelper";
import Custom404Component from "../src/pages/Custom404Component";
import SPlus3Custom404Component from "../src/pages/splus3/Custom404Component";
import { VARIANT } from "../src/config";

const Custom404: React.FC<{}> = () => {
  switch (VARIANT) {
    case "SPLUS":
    case "SCA":
      return <Custom404Component />
    case "SPLUS3":
    case "TV":
      return <SPlus3Custom404Component />
  }
}

export const getStaticProps: GetStaticProps = (context) => {
  return getLanguageStaticProps(context);
}

export default Custom404;